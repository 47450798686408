// noinspection GraphQLUnresolvedReference

import gql from 'graphql-tag';

const MUTATION = gql`
mutation MarkTourDoneById($id: ID!) {
  markTourDoneById(id: $id) {
    id
    label
    show
    settings
    steps {
      target
      content
    }
  }
}
`;

export default MUTATION;
